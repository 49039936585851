.backgroundSiderBar {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-position: left !important;
}

.logoWrapper {
  width: 28% !important;
  height: 200px;
  text-align: center;
}
.logoWrapper img {
  width: 52%;
}

.navItem {
  width: 100%;
  height: 50px;
  background-color: #eeeeee;
  /* border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px; */
  position: relative;
  /* margin: 30px 0px; */
  text-align: center;
  color: red;
}

.activeItem {
  color: blue !important;
}

.navItem::before,
.navItem::after {
  content: "";
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
}

.navItem::before {
  border-right: 10px solid #eeeeee;
  border-top: 10px solid #eeeeee;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  bottom: -20px;
}

.navItem::after {
  border-right: 10px solid #eeeeee;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #eeeeee;
  position: absolute;
  top: -20px;
}

.sideBarContainer {
  margin-top: 45px !important;
}

.loginContainer{
  z-index: 4 !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  font-family: "Poppins" !important;
  align-items: center !important;
  font-size: large;
}

/*
.top,
.bottom {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  overflow: hidden;
}

.top {
  top: -30px;
}

.bottom {
  bottom: -30px;
}

.top::before,
.bottom::before {
  content: "";
  position: absolute;
  right: 0;
  height: 200%;
  width: 200%;
  border-radius: 100%;
  box-shadow: 10px 10px 5px 100px blue;
  z-index: -1;
}

.top::before {
  top: -100%;
} */
