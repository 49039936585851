.premiumWrapper {
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  margin-top: 2rem;
  background-color: white;
  margin-left: 3rem;
}

.premiumWrapper h5 {
  text-align: center;
  margin-top: 1rem;
}

.premiumContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.premiumContainer1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-left: 100px;
  margin-right: 100px;
}

.premiumContainer2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.message {
  margin-top: 2rem;
  margin-left: 4rem;
  margin-right: 2rem;
}

.description {
  margin-top: 4rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
}
