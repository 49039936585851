@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.errorMessage {
  color: #ff0000;
}

.primarLight {
  background-color: #f7f9fb;
}

.primaryPurple {
  background-color: #e5ecf680;
}

.whiteBackground {
  background-color: #ffffff;
}

.primarLightColor {
  color: rgb(159, 158, 158);
}

.colorBlack {
  color: #000000;
}

.colorPrimaryBlack {
  color: #1c1c1c;
}

.colorGray {
  color: #8c8c8c !important;
}

.colorRed {
  color: #ff0000;
}

.vh100 {
  /* height: 100vh; */
  min-height: 100vh;
}

.milestones {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -30px;
  margin-bottom: -30px;
}

.borderRadious16 {
  border-radius: 16px;
}

/* Typography */
.Regular12 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.Regular14 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.Semibold14 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.Regular16 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 20px; */
}

.regular18 {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.title2 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
}

/* input */
.inputSelect {
  border-radius: 12px;
  border: white;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c;
  opacity: 1;

  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.alternateImage {
  width: 136px;
  height: 106px;
  background-color: #ffffff;
  color: #8c8c8c;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* image upload */
#customFileUpload::before {
  content: "Choose file";
  position: absolute;
  z-index: 2;
  display: block;
  color: #000000;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: -7px;
  margin-left: -7px;
  background: linear-gradient(#ececec, #acacac);
  border-radius: 10px;
}

/* Button */
.buttonColorGray {
  background-color: #e5ecf680 !important;
}

/* pointer */
.pointerArrow {
  cursor: pointer;
}

/* Milestone listing page */
.milestoneFilterSearch {
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;
}

.sortBtn {
  border-radius: 8px;
}

.milestoneFilter {
  border-radius: 8px;
  padding: 8px;
}

.milestoneSearch {
  border: 2px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  max-width: 200px;
}

.borderNone {
  border: none;
}

/* pagination */
.pagination {
  position: fixed;
  right: 30px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
}

.paginationNumber {
  padding: 4px 14px;
  border-radius: 8px;
  cursor: pointer;
}

.navbarSelected {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-decoration: underline;
}

/* milestoneErrorMessage */
.milestoneErrorMessage {
  display: flex;
  justify-content: center;
}

.hiddenFooterListingPage {
  background-color: #ffffff;
  height: 75px;
}

.paddingButton {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 30px;
  padding-left: 30px;
}

.mt12 {
  margin-top: 12px;
}

.selectHeight {
  height: 52px;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin-top: 10px;
}

.width100 {
  width: 100%;
}

.width100 .MuiInput-root {
  width: 100%;
}