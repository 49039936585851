@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
/*!

 =========================================================
 * Material Dashboard React - v1.9.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  height: 100%;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  .badge-primary-background-color: #9c27b0;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateY(-13%);
  transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}
.MuiTableCell-head {
  font-weight: bold !important;
}

.MuiTab-textColorInherit.Mui-selected {
  background: #4a148c;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 85, 139, 0.7);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 85, 139);
}

pre {
  white-space: pre-wrap;
}

html,
body {
  height: auto !important;
}

@media only screen and (min-width: 992px) {
  #nav-bar {
    display: none;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  #nav-bar {
    display: none;
  }
}

/* audio recorder modal */

._2fG9h {
  padding: 0px !important;
}
._1lB9c {
  display: none !important;
}
._37kfa::after {
  content: "Done" !important;
  visibility: visible;
  background: #185fec;
  color: #fff;
  margin-right: 75px;
  outline: 0;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  float: left;
}
._37kfa {
  visibility: hidden;
}
._1YOWG audio {
  margin-bottom: 50px;
}
._1Yplu {
  display: flex;
  align-items: center;
}
._dt3-T {
  padding-bottom: 10px !important;
}

.OTP_controls__1el3Q{
  margin-top: 0px !important;
}

.MuiListItemText-root {
  font-family: Poppins !important;
}

.ps__rail-y{
  display: block !important;
  opacity: 1;
}

.ps__thumb-y{
  width: 20px !important;
  min-height: 50px !important;
}
.Sidebar_backgroundSiderBar__iW4N6 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-position: left !important;
}

.Sidebar_logoWrapper__2cCKW {
  width: 28% !important;
  height: 200px;
  text-align: center;
}
.Sidebar_logoWrapper__2cCKW img {
  width: 52%;
}

.Sidebar_navItem__29QeW {
  width: 100%;
  height: 50px;
  background-color: #eeeeee;
  /* border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px; */
  position: relative;
  /* margin: 30px 0px; */
  text-align: center;
  color: red;
}

.Sidebar_activeItem__OvSFu {
  color: blue !important;
}

.Sidebar_navItem__29QeW::before,
.Sidebar_navItem__29QeW::after {
  content: "";
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
}

.Sidebar_navItem__29QeW::before {
  border-right: 10px solid #eeeeee;
  border-top: 10px solid #eeeeee;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  bottom: -20px;
}

.Sidebar_navItem__29QeW::after {
  border-right: 10px solid #eeeeee;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #eeeeee;
  position: absolute;
  top: -20px;
}

.Sidebar_sideBarContainer__3llSK {
  margin-top: 45px !important;
}

.Sidebar_loginContainer__3pSzE{
  z-index: 4 !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  font-family: "Poppins" !important;
  align-items: center !important;
  font-size: large;
}

/*
.top,
.bottom {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  overflow: hidden;
}

.top {
  top: -30px;
}

.bottom {
  bottom: -30px;
}

.top::before,
.bottom::before {
  content: "";
  position: absolute;
  right: 0;
  height: 200%;
  width: 200%;
  border-radius: 100%;
  box-shadow: 10px 10px 5px 100px blue;
  z-index: -1;
}

.top::before {
  top: -100%;
} */

.Dashboard_totalChapter__3zge1 {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px !important;
}
.Dashboard_divider__3CTs2 {
  margin: 18px;
}
.Dashboard_district_container__3shaR {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  align-self: baseline;
}

.Dashboard_dist_btn__2Dw-h {
  cursor: pointer;
  border: 1px solid silver;
  background-color: #f8f8f8;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
  transition-duration: 0.3s;
  margin: 0;
  font-size: 10px;
}
.Dashboard_graphbox__2x_jk {
  margin: 15px;
  width: 48%;
}
.Dashboard_standardWiseBox__LMj25 {
  display: flex;
  justify-content: space-between;
}
.Dashboard_subjectBoxCtrl__2PLUH {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Dashboard_dropdown__3vNFK {
  width: 20%;
}
.Dashboard_container__-Mkkl {
  display: "flex" !important;
  flex-wrap: "wrap" !important;
}

.Dashboard_chpterStatBox__3KLRV{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000A;
  border-radius: 4px;
  opacity: 1;
  height: 133px;
}

.Dashboard_statText__2jXma{
  text-align: left;
  font: normal normal 600 50px/76px Poppins;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

.Dashboard_copySvg__9Ho6b{
  font-size: 3.3rem !important;
}

.Dashboard_headerText__yy6OG{
  text-align: left;
  font: normal normal normal 28px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  padding-left: 20px;
}

.Dashboard_chart__1qZRs{
  background: #fff;
}

.Dashboard_chartHead__30wS5{
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.Dashboard_leftRoundedBtn__3xBwQ{
  height: 30px;
  border-radius: 50px 0 0 50px;
  display: flex;
  align-items: center;
}

.Dashboard_rightRoundedBtn__wWVUi{
  height: 30px;
  border-radius: 0 50px 50px 0;
  display: flex;
  align-items: center;
}

.Dashboard_btnText__1x015{
  text-align: left;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
}
.CheckingSection_search__1VPF7 {
    width: 100%;
    position: relative;
    display: flex;
    border-radius: 25px;
  }
  
  .CheckingSection_searchTerm__2Xkyo {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 26px 0 0 26px;
    outline: none;
    color: #9DBFAF;
    height: 46px;
    background: #F7F7FB;
    text-align: center;
  }
  
  .CheckingSection_searchTerm__2Xkyo:focus{
    color: gray;
  }
  
  .CheckingSection_searchButton__2M0lb {
    width: 55px;
    height: 46px;
    border: 1px solid #CCCCCC;
    background: #DADAE0;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0 26px 26px 0;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .CheckingSection_wrap__a5_Sz{
    padding: 20px;
  }

  .CheckingSection_published__112NZ{
    background: #D0EE7C 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .CheckingSection_inProgress__3HbFk{
    background: #FF987E 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .CheckingSection_inReview__1CcUo{
    background: #FFD358 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .CheckingSection_changesRequired__34q43{
    background: #FFCC80 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .CheckingSection_statusText__koDkr{
    color: var(--unnamed-color-333333);
    text-align: center;
    font: normal normal normal 16px/25px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  /* .filterIcon{
    fill: black;
  } */

  .CheckingSection_filterOriginal__1NVc2{
    background-color : #000;
    background: url(/static/media/filter_icon.672af21f.svg) no-repeat center / contain;
  }

  .CheckingSection_filterOpened__2Y97V{
    background-color: #ffffff; /* defines the background color of the image */
    mask: url(/static/media/filter_icon.672af21f.svg) no-repeat center / contain;
    -webkit-mask: url(/static/media/filter_icon.672af21f.svg) no-repeat center / contain;
  }

  .CheckingSection_filterIcon__S_ibB{
    height: 24px;
    width: 24px;
  }

  .createChapter_published__2TURQ{
    background: #D0EE7C 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .createChapter_inProgress__WFuPu{
    background: #FF987E 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .createChapter_inReview__2vR0e{
    background: #FFD358 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .createChapter_changesRequired__1n3Aa{
    background: #FFCC80 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

.ContentToolbar_container__WsTv6 {
  display: flex !important;
  flex-direction: row !important;
  width: 99.6%;
  margin: 0px !important;
  padding: 4px !important;
}
.ContentToolbar_no_spacing__19xhS {
  margin: 0px !important;
}
.ContentToolbar_button__3C4s0 {
  color: rgb(255, 255, 255) !important;
  margin: 0px 5px !important;
}

.ProgressBar_container__31kpE {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.ProgressBar_progressBar__XSnBw {
  width: 90%;
  margin: 0px 24px;
}

.Activity_courtesyContainer__32oBt {
  position: sticky;
  position: -webkit-sticky;
  top: 55px;
  z-index: 100;
}
.Activity_tabBar__qkEH8 {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 196px;
  z-index: 10;
}
.Activity_toolbarWrapper__3gAhV {
  display: "flex";
  flex-direction: "column";
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 244px;
  z-index: 10;
}
/* .toolbarWrapper button:not([name="span"]){
  display: none;
} */
.Activity_actionHeader__1JDO7 {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  z-index: 10;
  padding-top: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  background-color: #eeeeee;
}
.Activity_saveContainer__3WJes {
  padding-bottom: 17px;
  background-color: aliceblue;
}
.Activity_saveBtn__1Ip-L {
  margin-left: 23px !important;
  margin-top: 15px;
}
.Activity_label__13tig {
  font-size: 12px;
  color: gray;
}
.Activity_partField__18oFf {
  background: white;
  border: 3px solid black;
  border-radius: 4px;
  margin-top: 15px;
}
.Activity_deletePartContainer__sR6Wd {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}
.Activity_deletePartBtn__2q5ci {
  text-transform: capitalize !important;
}
.Activity_preview__32gLy {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px 15px;
  border: 1px solid gray;
  padding: 10px;
}

.Activity_item_actions__2yiCi {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.Activity_drag_handle__1o6og {
  border: 1px solid gray;
  display: flex;
  padding: 8px;
  border-radius: 5px;
}
.Activity_audioWrapper__3HJQs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Activity_audioPlayer__u0O9F {
  margin: 0px 36px;
  display: flex;
  justify-content: center;
}
.Activity_record__Ii1Ig {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

strong{
  font-weight: 700;
}
.student_chpterStatBox__1rnFI{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000A;
    border-radius: 4px;
    opacity: 1;
    height: 133px;
  }
  
  .student_statText__1LOT4{
    text-align: left;
    font: normal normal 600 35px/40px Poppins;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }

  .student_copySvg__1WXpG{
    font-size: 3rem !important;
  }

  .student_headerText__1eRk7{
    text-align: left;
    font: normal normal normal 28px/40px Roboto;
    letter-spacing: 0px;
    color: #43425D;
    opacity: 1;
    padding-left: 20px;
  }
  
  .student_search__3gQDP {
    width: 100%;
    position: relative;
    display: flex;
    border-radius: 25px;
  }
  
  .student_searchTerm__2vqBz {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 26px 0 0 26px;
    outline: none;
    color: #9DBFAF;
    height: 46px;
    background: #F7F7FB;
    text-align: center;
  }
  
  .student_searchTerm__2vqBz:focus{
    color: gray;
  }
  
  .student_searchButton__2RsOw {
    width: 55px;
    height: 46px;
    border: 1px solid #CCCCCC;
    background: #DADAE0;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0 26px 26px 0;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .student_wrap__4mu-J{
    padding: 20px;
  }

  .student_inputWrapper__1ZJcu{
    display: "flex";
    width: "100%";
    flex-wrap: "wrap";
    justify-content: "space-between";
  }

  .student_filterActions__3Hxyu{
    justify-content: flex-end
  }

  .student_filterOriginal__1Vs1p{
    background-color : #000;
    background: url(/static/media/filter_icon.672af21f.svg) no-repeat center / contain;
  }

  .student_filterOpened__NQsRW{
    background-color: #ffffff; /* defines the background color of the image */
    mask: url(/static/media/filter_icon.672af21f.svg) no-repeat center / contain;
    -webkit-mask: url(/static/media/filter_icon.672af21f.svg) no-repeat center / contain;
  }

  .student_filterIcon__TxOL6{
    height: 24px;
    width: 24px;
  }

  .Chapters_published__3uj5H{
    background: #D0EE7C 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .Chapters_inProgress__2OcfX{
    background: #FF987E 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .Chapters_inReview__ENUKw{
    background: #FFD358 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .Chapters_changesRequired__vFj9l{
    background: #FFCC80 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.linkColor {
    color: #FFFFFF;
}

.cardCategoryWhite {
    color: rgba(255, 255, 255, .62);
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.cardTitleWhite {
    color: #FFFFFF;
    margin-top: 0;
    min-height: auto;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 3px;
    text-decoration: none;
}

.dropdown {
    width: 45%;
    margin-top: 35px;
}

.cardFooter {
    justify-content: flex-end;
}

.cardBorder {
    box-shadow: none;
}

.paddingC {
    padding: 100px;
}

.width28 {
    /* width: 20rem; */
    width: 80%;
}

.width57 {
    width: 57rem;
}

.width15 {
    width: 15rem;
}

.width16 {
    width: 16rem;
}

.rowLimit {
    width: 10rem;
    height: 2.5rem;
}

.color8A {
    color: #0000008A;

}

.widthDialog {
    width: 1500px;
}

.colorRed {
    color: red;
}

/* for challenge list page */
.inputWrapper {
    /* display: flex;
    justify-content: space-around; */
    padding-top: 10px;
    padding-bottom: 10px;
}

.challengePageTitle {
    font-size: 26px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 10px;
}

.challengeListFilterDiv {
    background-color: #FFFFFF;
    border-radius: 8px;
}

.challengeListFilterDivV2 {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px 10px 20px 10px;
    margin: 20px 10px;
}

.challengeListDiv {
    background-color: #FFFFFF;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 20px;
    margin: 20px 10px 0 10px;
    min-height: 550px;
}

.backgroundColorGray {
    background-color: #f0f5fa;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
}

.bgYellow {
    background-color: #FFEEBA;
}

.bgBlue {
    background-color: #B8DAFF;
}

.bgGreen {
    background-color: #A5F1E9;
}

.bgRed {
    background-color: #FEA1A1;
}

.challengeListPaginationDiv {
    background-color: #FFFFFF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px 20px 5px 0;
    margin: 0 10px 20px 10px;
}

/* Date time picker */
.dateTimeRangeInputContainer {
    display: flex;
    /* flex-direction: column; */
}

/* .timePicker {
    width: 50%;
} */

label {
    margin-top: 8px;
    margin-bottom: 4px;
}

input {
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.errorText {
    color: red;
    font-size: 14px;
}

.validityDateErrorMsg {
    min-height: 20px;
    margin-top: 4px;
}

/*Date apply */
.applyButton {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.applyButton:hover {
    background-color: #45a049;
}

.tableCellWidth100px {
    max-width: 100px;
    overflow: auto;
}

.width100 {
    width: 100px;
}

.width110 {
    width: 110px;
}

.width80 {
    max-width: 80px;
}

.width250 {
    width: 250px;
}

.pointerA {
    cursor: pointer;
}

.searchHeight {
    height: 60px;
}

.selectSchool {
    max-height: 200px;
    overflow-y: auto;
}

inputWrapper .showSelectedSchools {
    border-radius: 50px;
    /* background-color: gray; */
    /* color: #45a049; */
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

/* .bgColorTag {
    background-color: ;
} */

.selectSchoolDropdown {
    /* color: #F4EEEE; */
    color: black;
    /* text-decoration-color: ;
    /* background-color: black; */
    /* text-decoration-color: black;  */

}

.bgColorTag {
    background-color: #F4F5F7;
}

/* .bgShadowTag {
    box-shadow: #3a1b1b;
    
} */

.paginationDiv {
    background-color: #FFFFFF;
    margin-right: 10px;
    margin-left: 10px;
}

.editBtn {
    min-height: 48px;
    width: 48px;
}

.arrowPointer {
    cursor: pointer;
}

.challengeSort {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: absolute;
    right: 35px;
    z-index: 999;
}

.crossBtn {
    margin-left: 6rem;
    cursor: pointer;
    padding: 10px;
    /* border-radius: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .crossBtn:hover{
    background-color: #d6e3e1;
} */

.sortElement{
    cursor: pointer;
    min-width:180px;
}

.sortElement:hover{
    background-color: #A5F1E9;
}
.errorMessage {
  color: #ff0000;
}

.primarLight {
  background-color: #f7f9fb;
}

.primaryPurple {
  background-color: #e5ecf680;
}

.whiteBackground {
  background-color: #ffffff;
}

.primarLightColor {
  color: rgb(159, 158, 158);
}

.colorBlack {
  color: #000000;
}

.colorPrimaryBlack {
  color: #1c1c1c;
}

.colorGray {
  color: #8c8c8c !important;
}

.colorRed {
  color: #ff0000;
}

.vh100 {
  /* height: 100vh; */
  min-height: 100vh;
}

.milestones {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -30px;
  margin-bottom: -30px;
}

.borderRadious16 {
  border-radius: 16px;
}

/* Typography */
.Regular12 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.Regular14 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.Semibold14 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.Regular16 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 20px; */
}

.regular18 {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.title2 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
}

/* input */
.inputSelect {
  border-radius: 12px;
  border: white;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c;
  opacity: 1;

  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.alternateImage {
  width: 136px;
  height: 106px;
  background-color: #ffffff;
  color: #8c8c8c;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* image upload */
#customFileUpload::before {
  content: "Choose file";
  position: absolute;
  z-index: 2;
  display: block;
  color: #000000;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: -7px;
  margin-left: -7px;
  background: linear-gradient(#ececec, #acacac);
  border-radius: 10px;
}

/* Button */
.buttonColorGray {
  background-color: #e5ecf680 !important;
}

/* pointer */
.pointerArrow {
  cursor: pointer;
}

/* Milestone listing page */
.milestoneFilterSearch {
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;
}

.sortBtn {
  border-radius: 8px;
}

.milestoneFilter {
  border-radius: 8px;
  padding: 8px;
}

.milestoneSearch {
  border: 2px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  max-width: 200px;
}

.borderNone {
  border: none;
}

/* pagination */
.pagination {
  position: fixed;
  right: 30px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
}

.paginationNumber {
  padding: 4px 14px;
  border-radius: 8px;
  cursor: pointer;
}

.navbarSelected {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-decoration: underline;
}

/* milestoneErrorMessage */
.milestoneErrorMessage {
  display: flex;
  justify-content: center;
}

.hiddenFooterListingPage {
  background-color: #ffffff;
  height: 75px;
}

.paddingButton {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 30px;
  padding-left: 30px;
}

.mt12 {
  margin-top: 12px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  background: white;
  border-radius: 12px;
}

.MuiAutocomplete-root fieldset {
  border-color: #e5ecf680 !important;
}

.selectHeight {
  height: 52px;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin-top: 10px;
}
/* 
svg.MuiSvgIcon-root.MuiSelect-icon {
  display: none;
} */

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  max-width: 500px;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.col-4.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  /* height: auto; */
  /* width: 100px; */
  white-space: break-spaces;
}

/* .MuiInputLabel-formControl {
  top: 0;
  left: unset;
}  */

.challengesSelectBox {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 12px;
}

.challengesSelectBox .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 15px) scale(1);
  pointer-events: none;
}

.challengesSelectBox .MuiOutlinedInput-root {
  border-radius: 12px;
}

.ChallengeOPT {
  max-width: 500px;
  min-width: 300px;
  white-space: break-spaces;
}
.challengesOption .MuiOutlinedInput-notchedOutline {
  border:0;

}

.width100 {
  width: 100%;
}

.width100 .MuiInput-root {
  width: 100%;
}

.height58 {
  height: 58px;
}
.errorMessage {
  color: #ff0000;
}

.primarLight {
  background-color: #f7f9fb;
}

.primaryPurple {
  background-color: #e5ecf680;
}

.whiteBackground {
  background-color: #ffffff;
}

.primarLightColor {
  color: rgb(159, 158, 158);
}

.colorBlack {
  color: #000000;
}

.colorPrimaryBlack {
  color: #1c1c1c;
}

.colorGray {
  color: #8c8c8c !important;
}

.colorRed {
  color: #ff0000;
}

.vh100 {
  /* height: 100vh; */
  min-height: 100vh;
}

.milestones {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -30px;
  margin-bottom: -30px;
}

.borderRadious16 {
  border-radius: 16px;
}

/* Typography */
.Regular12 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.Regular14 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.Semibold14 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.Regular16 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 20px; */
}

.regular18 {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.title2 {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
}

/* input */
.inputSelect {
  border-radius: 12px;
  border: white;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c;
  opacity: 1;

  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.alternateImage {
  width: 136px;
  height: 106px;
  background-color: #ffffff;
  color: #8c8c8c;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* image upload */
#customFileUpload::before {
  content: "Choose file";
  position: absolute;
  z-index: 2;
  display: block;
  color: #000000;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: -7px;
  margin-left: -7px;
  background: linear-gradient(#ececec, #acacac);
  border-radius: 10px;
}

/* Button */
.buttonColorGray {
  background-color: #e5ecf680 !important;
}

/* pointer */
.pointerArrow {
  cursor: pointer;
}

/* Milestone listing page */
.milestoneFilterSearch {
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;
}

.sortBtn {
  border-radius: 8px;
}

.milestoneFilter {
  border-radius: 8px;
  padding: 8px;
}

.milestoneSearch {
  border: 2px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  max-width: 200px;
}

.borderNone {
  border: none;
}

/* pagination */
.pagination {
  position: fixed;
  right: 30px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
}

.paginationNumber {
  padding: 4px 14px;
  border-radius: 8px;
  cursor: pointer;
}

.navbarSelected {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-decoration: underline;
}

/* milestoneErrorMessage */
.milestoneErrorMessage {
  display: flex;
  justify-content: center;
}

.hiddenFooterListingPage {
  background-color: #ffffff;
  height: 75px;
}

.paddingButton {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 30px;
  padding-left: 30px;
}

.mt12 {
  margin-top: 12px;
}

.selectHeight {
  height: 52px;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin-top: 10px;
}

.width100 {
  width: 100%;
}

.width100 .MuiInput-root {
  width: 100%;
}
.HMDashboard_render__SYNOb{
    display: flex;
    justify-content: center;
    align-items: center;
}
.SchoolDetail_header__Domi0{
    text-align: center; 
}

.SchoolDetail_headSection__2D6ik{
    display: flex;
    justify-content: space-between;
}

.SchoolDetail_modalHead__1zUJ2{
    display: flex;
    justify-content: space-between;
    font-weight: 800;
}

.SchoolDetail_formFields__qZtI2{
    display: flex;
    align-items: center;
}

.SchoolDetail_formHead__16LDv{
    font-weight: 800;
    font-size:20px;
}

.SchoolDetail_center__16U0k{
    justify-content: center;
    font-weight: 800;
}

.SchoolDetail_imgPreview__uu8zS{
    /* height: 150px; */
    width: 250px;
}

#SchoolDetail_imgPrev__2DhaW{
    transition: transform .2s; /* Animation */
}

#SchoolDetail_imgPrev__2DhaW:hover{ /* IE 9 */ /* Safari 3-8 */
    transform: scale(1.5); 
}.SchoolDetail_currentPageButton__2MzCD{
color: white;
font-size: 22px;
border-width: 0;
width: 250px;
height: 100px;
border-radius:10px;
margin-right: 10px;
background-color: #0DB41E;
}
.SchoolDetail_nextPageButton__3n-c9{
    color: #4B68BA;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 100px;
    margin-right: 10px;
    background-color: #EEF7FE;
    border-radius:10px;
}
.SchoolDetail_PhotoContainer__3m3gE{
    background-color: #ECFBFF;
    margin-top: 20px;
    padding: 30px;
}
.SchoolDetail_sliderText__2p5EG{
    font-size: 26px;
    font-weight: 600px;
    color: #958989;
}
.SchoolDetail_PhotoSubContainer__ZJKl3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.SchoolDetail_sliderImage__3OhIa{
    /* width: 600px; */
    /* height: 400px;
    background-size: cover; */
    max-width:500px;
    height:400px;
    width: auto;
    /* height: auto; */
  
}
.SchoolDetail_sliderButton__21Q9c{
    font-size: 36px;
    border:0;
    color: #C55B5B;
    background-color: #ECFBFF;
    
}
.SchoolDetail_typeOfImage__3vTkG{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.SchoolDetail_imageTypeText__jagfP{
    font-size: 20px;
    color: black;
   margin: 4px;
}
.SchoolDetail_imageTypeTextActive__34cq6{
    color: #7F21B8;
}
.SchoolDetail_headMasterText__2wqCS{
    color: #958989;
    font-size: 22px;
    min-width: 350px;
    margin-top: 10px;

}
.SchoolDetail_headMasterData__1tyDV{
    display: flex;
    flex-direction: row;

}
.SchoolDetail_headMasteContainer__2qfPX{
    display: flex;
flex-direction: row;
align-items: center ;
}
.SchoolDetail_inputClass__1VGb6{
border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
}
.SchoolDetail_nameText__t5wNp{
 color: #415EB6;
 font-size: 16px;
 margin-top: 14px;
}
.SchoolDetail_nameTextContainer__2iyI9{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 230px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.SchoolDetail_nameTextContainer1__asGfG{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.SchoolDetail_nameTextContainer2__19IhS{
    border:0;
    /* background-color: #DFE4E8;   */
    height: 40px;
    /* margin-right: 10px;  */
    /* margin-left: 10px;   */
    /* padding-left: 10px; */
    width: 270px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.SchoolDetail_schoolStudentsTotal__3UEIZ{
    border:0;
    background-color: #DFE4E8;  
    height: 200px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.SchoolDetail_schoolStudentsTotalText__2yjCy{
    font-size: 30px;
    color: #415EB6;
}
.SchoolDetail_schoolStudentsTotaBoy__3kj7c{
    border:0;
    background-color: #DFE4E8;  
    height: 95px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    margin-bottom: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.SchoolDetail_vidaythiSankhayaMiddle__1hzQn{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.SchoolDetail_inputClassBoy__1vOHE{
    border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
margin-bottom: 10px;
}
.SchoolDetail_inputClassTotal__317I6{
    border:0;
    background-color: #DFE4E8;  
    height: 90px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
  
}
.SchoolDetail_inputClassCount__eNU7S{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
    margin-top: 5px;
}
.SchoolDetail_submitButtonContainer__2yV0a{
    display: flex;
        
justify-content: center;
}
.SchoolDetail_submitButton__2OgBx{
    background-color: #B3BBB3;
    border-radius: 10px;
    color: black;
    width: 200px;
    border: none;
    height: 40px;
    margin: 10px;
}


.ChapterComments_text__2cGo1 {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .ChapterComments_label__1V93o {
    color: gray;
  }
  .ChapterComments_root__2-UvE {
    position: relative;
  }
  .ChapterComments_editContainer__2W0bT {
    position: absolute;
    right: 20px;
  }
  .ChapterComments_pagination__1GeK1 > li {
    display: inline-block;
    padding-left: 0;
  }
  .ChapterComments_pagination__1GeK1 > li {
    list-style: none;
    border: 0.9px solid;
  }
  .ChapterComments_pagination__1GeK1 > li > a,
  .ChapterComments_pagination__1GeK1 > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #2c689c;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  
  .ChapterComments_pagination__1GeK1>li.ChapterComments_active__B8SIz>a {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  /* Style the active class (and buttons on mouse-over) */
  .ChapterComments_pagination__1GeK1 > li > a:hover {
    background-color:  #218838;
    color: white;
    cursor: pointer;
  }
  .ChapterComments_pagination__1GeK1 > li:first-child > a,
  .ChapterComments_pagination__1GeK1 > li:first-child > span {
    margin-left: 0;
      padding: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    display: none!important;
  }
  .ChapterComments_pagination__1GeK1 > li:last-child > a,
  .ChapterComments_pagination__1GeK1 > li:last-child > span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px!important;
    border-top-right-radius: 4px;
    display: none!important;
  }
.SMCCreateForm_header__IicGL{
    text-align: center; 
}

.SMCCreateForm_headSection__21-MI{
    display: flex;
    justify-content: space-between;
}

.SMCCreateForm_modalHead__1YXFl{
    display: flex;
    justify-content: space-between;
    font-weight: 800;
}

.SMCCreateForm_formFields__2UTfQ{
    display: flex;
    align-items: center;
}

.SMCCreateForm_formHead__3JCdl{
    font-weight: 800;
}

.SMCCreateForm_center__3LKeq{
    justify-content: center;
    font-weight: 800;
}

.SMCCreateForm_imgPreview__1n5SB{
    height: 150px;
    width: 250px;
}

#SMCCreateForm_imgPrev__2hi-i{
    transition: transform .2s; /* Animation */
}

#SMCCreateForm_imgPrev__2hi-i:hover{ /* IE 9 */ /* Safari 3-8 */
    transform: scale(1.5); 
}
.SMCCreateForm_currentPageButton__1JDP5{
color: white;
font-size: 22px;
border-width: 0;
width: 250px;
height: 100px;
border-radius:10px;
margin-right: 10px;
background-color: #0DB41E;
}
.SMCCreateForm_nextPageButton__nbfoD{
    color: #4B68BA;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 100px;
    margin-right: 10px;
    background-color: #EEF7FE;
    border-radius:10px;
}
.SMCCreateForm_PhotoContainer__3E-Fs{
    background-color: #ECFBFF;
    margin-top: 20px;
    padding: 30px;
}
.SMCCreateForm_sliderText__3eqpA{
    font-size: 26px;
    font-weight: 600px;
    color: #000000;
}
.SMCCreateForm_PhotoSubContainer__EXOix{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.SMCCreateForm_sliderImage__30_LT{
    /* width: 600px; */
    /* height: 400px;
    background-size: cover; */
    max-width:500px;
    height:350px;
    width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    /* height: auto; */
  
}
.SMCCreateForm_sliderButton__3096Y{
    font-size: 36px;
    border:0;
    color: #C55B5B;
    background-color: #ECFBFF;
    
}
.SMCCreateForm_typeOfImage__nQ1Hc{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.SMCCreateForm_imageTypeText__25mTk{
    font-size: 20px;
    color: black;
   margin: 10px;
}
.SMCCreateForm_redText__3cbNB{
    color: red;
}
.SMCCreateForm_redTextMiddle__NBnsf{
    color: red;
    margin-left: 360px;
}
.SMCCreateForm_redTextMiddleSlider__3JZu8{
    text-align: center;
    color: red;
}
.SMCCreateForm_imageTypeTextActive__3fuRt{
    color: #7F21B8;
}
.SMCCreateForm_headMasterText__1rQcL{
    color: #958989;
    font-size: 26px;
    min-width: 400px;
    margin-top: 10px;

}
.SMCCreateForm_imageHeadingText__1ZvmH{
    color: #958989;
    /* font-size: 26px; */
    width: 250px;
    margin-top: 10px;
    /* margin-right: 20px; */
}
.SMCCreateForm_headMasterData__3iNtg{
    display: flex;
    flex-direction: row;

}
.SMCCreateForm_headMasteContainer__18Ilr{
    display: flex;
flex-direction: row;
align-items: center ;
}
.SMCCreateForm_vidaythiSankhayaContainer__2d6mY{
    display: flex;
flex-direction: row;   
}
.SMCCreateForm_inputClass__qGDMZ{
border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
}
.SMCCreateForm_nameText__3awLm{
 color: #415EB6;
 font-size: 16px;
 margin-top: 14px;
}
.SMCCreateForm_nameTextContainer__2JigR{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
    .SMCCreateForm_addressTextContainer__23Vov{
        border:0;
        background-color: #DFE4E8;  
        height: 80px;
        margin-bottom: 5px;
        margin-right: 10px; 
        margin-left: 10px;  
        padding-left: 10px;
        width: 330px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }
.SMCCreateForm_schoolStudentsTotal__2vzn7{
    border:0;
    background-color: #DFE4E8;  
    height: 200px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 175px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}
.SMCCreateForm_schoolStudentsTotalText__2X1CB{
    font-size: 30px;
    color: #415EB6;
}
.SMCCreateForm_schoolStudentsTotaBoy__NKYlj{
    border:0;
    background-color: #DFE4E8;  
    height: 95px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    margin-bottom: 10px;
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}
.SMCCreateForm_vidaythiSankhayaMiddle__1bN0g{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.SMCCreateForm_schoolStudetsInput__1KkcG{
    border:0;
    background-color: #DFE4E8; 
    height: 45px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 100px;

}
.SMCCreateForm_inputClassBoy__2dh8t{
    border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
margin-bottom: 10px;
}
.SMCCreateForm_inputClassTotal__2XGC9{
    border:0;
    background-color: #DFE4E8;  
    height: 90px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
  
}
.SMCCreateForm_inputClassCount__3KG6o{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
    margin-top: 5px;
}
.SMCCreateForm_submitButtonContainer__3A9v0{
    display: flex;   
justify-content: center;
margin-bottom: 40px;
}

.SMCCreateForm_submitButton__26esB{
    background-color: #B3BBB3;
    border-radius: 10px;
    color: black;
    width: 200px;
    border: none;
    height: 40px;
    margin: 10px;
}
.SMCCreateForm_committeeContainer__3R6f8{
    display: flex;
    flex-direction: "row";
    align-items: "center";
    margin-top:20px;
}
.SMCCreateForm_committeeNameInput__lyLV1{
color:#415EB6;
border:0;
background-color: #DFE4E8;
width:220px;
height:46px;
margin-left: 30px;
margin-right:20px;
padding-left: 10px;
}
.SMCCreateForm_committeeGenderInput__vMzqx{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:200px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.SMCCreateForm_committeeCategoryInput__Wci7t{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:200px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.SMCCreateForm_teacherCasteSelect__3HjJQ{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:100px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.SMCCreateForm_committeeMobileInput__3Jekc{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:150px;
    height:46px;
    padding-left: 10px;
        margin-right:20px;
}
.SMCCreateForm_committeeDateInput__1EgpU{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    min-width:150px;
    height:46px;
    margin-right:20px;
    margin-left: 30px;
    padding-left: 10px;
}
.SMCCreateForm_committeeAttendanceInput__3zyV3{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:150px;
    height:46px;
    padding-left: 10px;
    margin-right:20px;
}
.SMCCreateForm_committeeDetailsInput__1XCEP{

    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:350px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.SMCCreateForm_committeeDetailsMeetings__3x4J4{

    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    max-width:200px;
    
    height:46px;
    margin-right:10px;
    padding-left: 10px;
}

.SMCCreateForm_StudentsAndClassContainer__2xHqJ{
    display: flex;
    flex-direction: row;

}
.SMCCreateForm_nameTextContainerClass__2NEYb{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 165px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    
}
.SMCCreateForm_nameTextContainerClass1__2w5O9{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    
}
.SMCCreateForm_nameTextClass__1Az3M{
    color: #415EB6;
    font-size: 16px;
    margin-top: 14px;
}

.SMCCreateForm_redTextMiddleClass__30yUA{
    color: red;
    margin-left: 10px;
}
.SMCCreateForm_redStar__Lzhef{
    margin-left: 5px;
    color:red;
    margin-right: 5px;
    
}
.SMCCreateForm_uploadText__nFmqC{
    color:#415EB6;
    font-size: 13px;
}
.SMCCreateForm_reminderContainer__3s1ZI{
    display: flex;
    flex-direction: column;

}
.SMCCreateForm_remiderTextPrathmik__1In-g{
    color:#958989;
    font-size: 12px;
}
.SMCCreateForm_imageContainer__1pojI{
    display: flex;
    margin-top: 10px;
    flex-direction: row;
}
.SMCCreateForm_competitiveExamText__eRcKQ{
    min-width: 250px;
    padding: 10px;
    color:#958989 ;
}
.SMCCreateForm_uniformText___2lbY{
    min-width: 350px;
    padding: 10px; 
    color: #958989;  
}
.SMCCreateForm_teacherCaste__22OsK{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:101px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;   
}
.SMCCreateForm_schoolStudentsTotal__2vzn7::placeholder{
font-size:14px
}
.SMCCreateForm_schoolStudetsInput__1KkcG::placeholder{
    font-size: 10.5px;
}
.SMCCreateForm_teachersNumberPlaceHolder__3LRzW::placeholder{
    font-size: 13px;
}
.SMCCreateForm_fileNameText__1dc7T{
    font-size: 10px;
    margin-left: 6px;
}
.FLNHome_render__2BSPv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.FLNAnalysis_render__2gAgE{
    display: flex;
    justify-content: center;
    align-items: center;
}
.FLNAnalysis_currentPageButton__agEFM{
    color: #fff;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    border-radius:10px;
    margin-right: 10px;
    background-color: #6872DD;
    }
    .FLNAnalysis_nextPageButton__ft7u3{
        color: #000;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #D9D7DA;
        border-radius:10px;
    }
.LOAnalysis_render__2J4KY{
    display: flex;
    justify-content: center;
    align-items: center;
}
.LOAnalysis_currentPageButton__Jvtk4{
    color: #fff;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    border-radius:10px;
    margin-right: 10px;
    background-color: #6872DD;
    }
    .LOAnalysis_nextPageButton__1i_i6{
        color: #000;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #D9D7DA;
        border-radius:10px;
    }
.VSAnlytics_render__hukPh{
    display: flex;
    justify-content: center;
    align-items: center;
}
.VSAnlytics_currentPageButton__2oijf{
    color: #000;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    /* border-radius:10px; */
    margin-right: 10px;
    background-color: #D9D7DA;
    }
    .VSAnlytics_nextPageButton__VWRn0{
        color: #FFF;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #6872DD;
        /* border-radius:10px; */
    }
.SMCAnalytics_render__4dGRr{
    display: flex;
    justify-content: center;
    align-items: center;
}
.SMCAnalytics_currentPageButton__37cmq{
    color: #000;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    /* border-radius:10px; */
    margin-right: 10px;
    background-color: #D9D7DA;
    }
    .SMCAnalytics_nextPageButton__10pl5{
        color: #FFF;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #6872DD;
        /* border-radius:10px; */
    }
.FLNAM_render__11oQO{
    display: flex;
    justify-content: center;
    align-items: center;
}
.FLNAM_currentPageButton__QmDrU{
    color: #000;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    /* border-radius:10px; */
    margin-right: 10px;
    background-color: #D9D7DA;
    }
    .FLNAM_nextPageButton__1Fwzk{
        color: #FFF;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #6872DD;
        /* border-radius:10px; */
    }
.SMCAM_render__16ieA{
    display: flex;
    justify-content: center;
    align-items: center;
}
.SMCAM_currentPageButton__3Xa8p{
    color: #000;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    /* border-radius:10px; */
    margin-right: 10px;
    background-color: #D9D7DA;
    }
    .SMCAM_nextPageButton__3Jbyw{
        color: #FFF;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #6872DD;
        /* border-radius:10px; */
    }
.SpecialCourse_inputField__5hMh_{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:220px;
    height:46px;
    margin-top: 10px;
   
    }
    .SpecialCourse_addressTextContainer__Pg7FQ{
        border:0;
        background-color: #DFE4E8;  
        height: 80px;
       margin-top: 10px;
        width: 430px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }
.LearningOutcome_render__1ptk3{
    display: flex;
    justify-content: center;
    align-items: center;
}
.LearningOutcome_currentPageButton__14_tk{
    color: #fff;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    border-radius:10px;
    margin-right: 10px;
    background-color: #6872DD;
    }
    .LearningOutcome_nextPageButton__2hvuP{
        color: #000;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #D9D7DA;
        border-radius:10px;
    }
.premiumWrapper {
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  margin-top: 2rem;
  background-color: white;
  margin-left: 3rem;
}

.premiumWrapper h5 {
  text-align: center;
  margin-top: 1rem;
}

.premiumContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.premiumContainer1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-left: 100px;
  margin-right: 100px;
}

.premiumContainer2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.message {
  margin-top: 2rem;
  margin-left: 4rem;
  margin-right: 2rem;
}

.description {
  margin-top: 4rem;
}

.buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
}

.createChapterPart_contentToolbar__2tDQ4 {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 48px;
  z-index: 10;
}
.createChapterPart_cardBody__3V1hP {
  background-color: aliceblue;
}
.createChapterPart_textField__22jgk {
  border: 1px solid black;
  border-radius: 4px;
  background: white;
}
.createChapterPart_preview__y17ti {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px 15px;
  border: 1px solid gray;
  padding: 10px;
}
.createChapterPart_item_actions__27eJc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.createChapterPart_drag_handle__3nCbt {
  border: 1px solid gray;
  display: flex;
  padding: 8px;
  border-radius: 5px;
}
.createChapterPart_audioWrapper__1emsh {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.createChapterPart_audioPlayer__2Wh-Y {
  margin: 0px 36px;
  display: flex;
  justify-content: center;
}
.createChapterPart_record__dZ90I {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.createChapterPart_chapterDetail__1LuoD span{
  padding-right: 10px;
}

.createChapterPart_chapterTitle__3uidZ{
  text-align: left;
  font: normal normal normal 22px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
}

.createChapterPart_subjectDetail__1Gc4k{
  text-align: left;
  font: normal normal normal 18px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 0.7;
}

.createChapterPart_otherChapDetail__2ZWnd{
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.createChapterPart_detailTitle__1B0Qn{
  text-align: left;
  font: normal normal bold 16px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
}

.createChapterPart_publishBtn__3vggq{
  background: var(--unnamed-color-3b86ff) 0% 0% no-repeat padding-box !important;
  background: #3B86FF 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}

.createChapterPart_addWidgetDiv__2M6Au{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  opacity: 1;
  width: 71vw;
  height: 154px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  cursor: pointer;
}
.createChapterPart_addWidgetBtn__2hO-w{
  position: relative !important;
  z-index: 1 !important;
  display: none !important;
  align-items: center !important;
  justify-content: center !important;
  left: 25vw;
}

.createChapterPart_widgets__1nXat:hover > .createChapterPart_addWidgetBtn__2hO-w{
  display: inline-block !important;
}

.createChapterPart_MuiGrid-root__2NTNw > :has(input:focus){
  border:blue;
}
.ChapterPreview_courtesyContainer__yKhfg {
  position: sticky;
  position: -webkit-sticky;
  top: 55px;
  z-index: 100;
}
.ChapterPreview_tabBar__3chqH {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 196px;
  z-index: 10;
}
.ChapterPreview_toolbarWrapper__3mUw6 {
  display: "flex";
  flex-direction: "column";
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 244px;
  z-index: 10;
}
.ChapterPreview_actionHeader__2IvKk {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  z-index: 10;
  padding-top: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  background-color: #eeeeee;
}
.ChapterPreview_saveContainer__2QvRF {
  padding-bottom: 17px;
  background-color: #fff;
}
.ChapterPreview_saveBtn__1c7Xp {
  margin-left: 23px !important;
  margin-top: 15px;
}
.ChapterPreview_label__2pnAC {
  font-size: 12px;
  color: gray;
}
.ChapterPreview_partField__XwF4Y {
  background: white;
  border: 3px solid black;
  border-radius: 4px;
  margin-top: 15px;
}
.ChapterPreview_deletePartContainer__21ROv {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}
.ChapterPreview_deletePartBtn__RN3n5 {
  text-transform: capitalize !important;
}
.ChapterPreview_preview__33cce {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px 15px;
  border: 1px solid gray;
  padding: 10px;
}

.ChapterPreview_item_actions__zc3d8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.ChapterPreview_drag_handle__U_Gu_ {
  border: 1px solid gray;
  display: flex;
  padding: 8px;
  border-radius: 5px;
}
.ChapterPreview_audioWrapper__3AAFL {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ChapterPreview_audioPlayer__3AFKZ {
  margin: 0px 36px;
  display: flex;
  justify-content: center;
}
.ChapterPreview_record__2iCLS {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ChapterPreview_editPartBtn__343W1{
  float: right;
  margin: 15px 25px !important;
}
.ChapterPreview_editTagBtn__3OneX{
  margin: 15px 25px !important;
}


.ChapterPreview_chapterDetail__1q7-a span{
  padding-right: 10px;
}

.ChapterPreview_chapterTitle__3q1_S{
  text-align: left;
  font: normal normal normal 22px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
}

.ChapterPreview_subjectDetail__22Y1d{
  text-align: left;
  font: normal normal normal 18px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 0.7;
}

.ChapterPreview_otherChapDetail__1HY2b{
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.ChapterPreview_detailTitle__3PdBd{
  text-align: left;
  font: normal normal bold 16px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
}

.ChapterPreview_headerTitle__1uOjA{
  text-align: left;
font: normal normal normal 28px/40px Roboto;
letter-spacing: 0px;
color: #43425D;
opacity: 1;
}

.ChapterPreview_reviewCls__wDdXL{
  border-radius: 4px !important;
  opacity: 1 !important;
  height: 50px !important;
}

.ChapterPreview_changeStatusBtn__2nE3L{
background: #3B86FF 0% 0% no-repeat padding-box !important;
border-radius: 4px !important;
opacity: 1 !important;
color: #fff !important;
margin-left: 10px !important;
height: 50px !important;
}

.ChapterPreview_addWidgetDiv__3SlPQ{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  opacity: 1;
  width: 71vw;
  height: 154px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  cursor: pointer;
}
.ChapterPreview_addWidgetBtn__XCjAW{
  position: relative !important;
  z-index: 1 !important;
  display: none !important;
  align-items: center !important;
  justify-content: center !important;
  left: 25vw;
}

.ChapterPreview_widgets__1sMKM:hover > .ChapterPreview_addWidgetBtn__XCjAW{
  display: inline-block !important;
}
.ChapterPreview_item_actions__zc3d8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ChapterPreview_published__6vdcc{
  background: #D0EE7C 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.ChapterPreview_inProgress__8SCcN{
  background: #FF987E 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.ChapterPreview_inReview__Wcfey{
  background: #FFD358 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.ChapterPreview_changesRequired__2o5U7{
  background: #FFCC80 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.ChapterPreview_break-before__2bu5n {
  page-break-before: always;
}

.ChapterPreview_break-after__15GaW {
  page-break-after: always;
}

.ChapterPreview_break-avoid__3EHaG {
  page-break-inside: avoid;
}

strong{
  font-weight: 700;
}
@media screen and (max-width: 959px) {
  .ChapterPreview_cardContent__2qwUE
  {
    margin: auto;
  }

  .ChapterPreview_modalGridOuter__1S6Ua
  {
    width: 70%;
  }
}

@media screen and (max-width: 480px) {
  .ChapterPreview_modalGridOuter__1S6Ua
  {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .ChapterPreview_cardContent__2qwUE
  {
    width: 100% !important;
  }
}

.ChapterPreview_tableStyle__2ebpc
{
   margin-top: 1.5em; 
}
.ChapterPreview_tableStyle__2ebpc td
{ 
  margin-top: 1.5em;
   border-color: inherit;
    border-width: 1px;
 }
.CreditCourtesy_text__3NhKx {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CreditCourtesy_label__1-o7o {
  color: gray;
}
.CreditCourtesy_root__1VmhY {
  position: relative;
}
.CreditCourtesy_editContainer__33N9C {
  position: absolute;
  right: 20px;
  text-align: right !important;
}

.Login_container__pyxwz {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.Login_form_container__3HcBO {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1;
}

.Login_image__14rcr {
  background: url(/static/media/slider1.28d4aca1.webp);
  background-repeat: no-repeat;
  flex: 1 1;
  background-position: center;
  transform: scaleX(-1);
}

.Login_section__30jSs {
  padding-top: 180px;
  flex: 1 1;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login_card__38QKc {
  display: flex;
  flex-direction: column;
  padding: 36px;
  width: 50%;
  background: white;
  border-radius: 4px;
  border: 1px solid lightgray;
}
.Login_input__1ns9f {
  margin: 15px 0px !important;
}
.Login_submitBtn__1RqmA {
  width: 100%;
}
.Login_btContainer__wEpNr {
  margin-top: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Login_hint__1dRQF {
  font-size: 12px;
  color: gray;
  margin-bottom: 18px;
}
.Login_label__2AyS0 {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}
.Login_header__2oedI {
  width: 56%;
  margin-bottom: 20px;
}
.Login_header__2oedI h3 {
  font-weight: bold;
  font-size: 40px;
  color: #01558b;
  margin: 0px;
}
.Login_sliderWrapper__3Qo-J {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.Login_logo__3KWgj {
  width: 185px;
  height: auto;
  position: absolute;
  top: 30px;
  right: 60px;
  object-fit: contain;
}

.Login_sliderContainer__2xWQ7 {
  padding-top: unset;
  align-self: center;
  align-items: flex-start;
}

.form_logo__39siu {
  width: 200px;
  height: 100px;
  object-fit: contain;
  margin: 0 auto;
}
.form_container__27aAd {
  width: 365px;
}
.form_logoContainer__zI4vm {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form_logoText__3o4ng {
  text-align: left;
  font: normal normal normal 43px/52px Roboto;
  letter-spacing: 0px;
  color: #0eafd7;
  text-transform: uppercase;
  opacity: 1;
}
.form_logoTagLine__1I1I0 {
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 5px;
}
.form_form__ajGzJ {
    margin-top: 30px;
    flex-direction: column;
    display:flex;
}
.form_textInput__1PZgW {
    margin-bottom: 20px !important
}
.form_signBtn__3W6Gs {
    width:100%;
    padding: 2px 0px !important;
    background-color:#C4E3C6 !important;
    font-size: 18px !important; 
    color:#4b4b4b 
}
.form_signBtn1__11LxD {
  width:100%;
  padding: 12px 0px !important;
  background-color:#55D75A !important;
  font-size: 18px !important; 
  color:'#000'
}
.form_signBtn2__1Msdo {
  width:100%;
  padding: 12px 0px !important;
  background-color:#B3BBB3 !important;
  font-size: 18px !important; 
  color: #4b4b4b;
}
.form_label__2BiSR {
    color: #4B4B4B;
    font-size: 16px;
    margin-bottom:10px
}
.form_dropdown__3cCE6 {
    border: 1px solid #bdbdbd;
    padding: 10px;
    border-radius: 4px;
    margin-top: 0px !important;
}
.form_btContainer__15BUs {
  margin-top: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Alert_alert__13I1A {
  text-align: center;
  padding: 8px;
  color: white;
  border-radius: 4px;
  margin-bottom: 15px;
}

.Alert_success__1Ir96 {
  background-color: lightgreen;
}

.Alert_danger__1Oz9H {
  background-color: salmon;
}


.OTP_heading__3188F {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #4B4B4B;
  opacity: 1;
}
.OTP_input__2aWkM input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.OTP_controls__1_Ecn {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.OTP_input__2aWkM {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}
.OTP_resend__2smkI {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.OTP_hint__y2VPs {
  color: gray;
  font-weight: bold;
}
.OTP_root__26U8k {
  margin-top: 10%;
}
.OTP_resend__2smkI a {
  margin-left: 5px;
  cursor: pointer;
  color: #0eafd7;
}

.OTP_container__2_Zqc {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.OTP_form_container__3QUxn {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1;
}

.OTP_image__2vVh3 {
  background: url(/static/media/slider1.28d4aca1.webp);
  background-repeat: no-repeat;
  flex: 1 1;
  background-position: center;
  transform: scaleX(-1);
}

.OTP_section__2gfwc {
  padding-top: 180px;
  flex: 1 1;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.OTP_card__1AD0h {
  display: flex;
  flex-direction: column;
  padding: 36px;
  width: 50%;
  background: white;
  border-radius: 4px;
  border: 1px solid lightgray;
}
.OTP_input__2aWkM {
  margin: 15px 0px !important;
}
.OTP_submitBtn__23vE3 {
  width: 100%;
  background-color: #0eafd7 !important;
  height: 53px;
  font: normal normal normal 18px/27px Poppins !important;
  letter-spacing: 0px !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
}
.OTP_submitBtn__23vE3:disabled{
  color: #000;
  background-color:lightgray !important;
}
.OTP_btContainer__1JfA2 {
  margin-top: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.OTP_hint__y2VPs {
  font-size: 12px;
  color: gray;
  margin-bottom: 18px;
}
.OTP_label__3pIFO {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}
.OTP_header__12KYR {
  width: 56%;
  margin-bottom: 20px;
}
.OTP_header__12KYR h3 {
  font-weight: bold;
  font-size: 40px;
  color: #01558b;
  margin: 0px;
}
.OTP_sliderWrapper__3ppvM {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.OTP_logo__3i_cZ {
  width: 221px;
  height: auto;
  /* position: absolute;
  top: 30px;
  right: 60px; */
  object-fit: contain;
}

.OTP_sliderContainer__3ozK_ {
  padding-top: unset;
  align-self: center;
  align-items: flex-start;
}

.OTP_alignCenter__2c2Nk{
  display: flex;
  justify-content: center;
}
.OTP_logoContainer__16YKb {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OTP_logoText__9jkRn {
  text-align: left;
  font: normal normal normal 43px/52px Roboto;
  letter-spacing: 0px;
  color: #0eafd7;
  text-transform: uppercase;
  opacity: 1;
}
.OTP_logoTagLine__gT10c {
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 5px;
}
.OTP_mobileNumber__1cv-9{
  color: var(--unnamed-color-10acdc);
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #10ACDC;
  opacity: 1;
}
.ErrorBoundary_container__Vkogn {
  background: #f4f4f4;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

